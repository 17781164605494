import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/bestpractice/bestpractice/node_modules/gatsby-theme-docz/src/base/Layout.js";
import APIDoc from "../../src/components/apidoc";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "service-map"
    }}>{`Service Map`}</h1>
    <p>{`Provides service point data with advanced filtering for interactive service point applications.`}</p>
    <p>{`The Service Map Backend API is the RESTful data source which provides data about services and service points in the Helsinki Metropolitan area, as well as detailed accessibility information on select service points. This API has been designed to be used live in interactive applications. It also includes features such as Helsinki administrative divisions (eg. neighborhoods) and geocoding, including reverse geocoding.`}</p>
    <ul>
      <li parentName="ul">{`API location: `}<a parentName="li" {...{
          "href": "https://api.hel.fi/servicemap/v2/"
        }}>{`api.hel.fi/servicemap/v2/`}</a></li>
    </ul>
    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>

    <APIDoc doc="https://raw.githubusercontent.com/City-of-Helsinki/smbackend/master/specification.swagger2.0.yaml" mdxType="APIDoc" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      